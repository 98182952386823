/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");

body {
  overflow-x: hidden !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
  font-family: "Roboto", sans-serif;
  color: #343434;
  min-width: 0px !important;
  background-color: #f3f3f3;
}

html,
body {
  overflow: auto;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ededed;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #beeecb;
    border-radius: 8px;
    width: 7px;
    background-clip: content-box;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #9fecb4;
  }
}

@media screen and (max-width: 767px) {
  body {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
  }

  body::-webkit-scrollbar {
    display: none !important;
  }
}

a {
  color: inherit !important;
}

a:hover {
  text-decoration: none !important;
  color: inherit !important;
}

button:focus {
  outline: none;
}

.th-roboto {
  font-family: "Roboto", sans-serif;
}

.th-poppins {
  font-family: "Poppins", sans-serif;
}

.th-fair-play {
  font-family: "Playfair Display", serif;
}

/* font color */
.th-black {
  color: #000000;
}

.th-black-1 {
  color: #3f4040;
}

.th-grey {
  color: #f0f0f3;
}

.th-lt-grey {
  color: #afaeaf;
}

.th-md-grey {
  color: #8b8c8c;
}

.th-dark-grey {
  color: #777979;
}

.th-grey-2 {
  color: #5e6282;
}

.th-grey-3 {
  color: #c4c4c4;
}

.th-grey-5 {
  color: #7d7d7d;
}

.th-lt-grey-1 {
  color: #767676;
}

.th-white {
  color: #ffffff;
}

.th-primary {
  color: #75cf8f;
}

.th-yellow {
  color: #ffb500;
}

.th-green {
  color: #92e3a9;
}

.th-blue {
  color: #3f4d90;
}

.th-grey-4 {
  color: #474747;
}

.th-text-hint {
  color: #747b8d;
}

.th-lt-blue {
  color: #407bff;
}

/* Link */
.th-link {
  color: #000000;
}

.th-link:hover {
  text-decoration: none;
  /* color: #75CF8F; */
}

.th-link:active {
  text-decoration: none;
  /* color: #75CF8F; */
}

.th-link-white {
  color: #fff;
}

.th-link-white:hover {
  text-decoration: none;
  color: #fff !important;
}

.th-link-white:active {
  text-decoration: none;
  /* color: #75CF8F; */
}

.th-link-1:hover {
  text-decoration: none;
  color: #ffffff;
}

.th-link-black {
  color: #000000;
}

.th-link-black:hover {
  text-decoration: none;
  color: #000000;
}

.th-link-black:active {
  text-decoration: none;
  color: #000000;
}

/* BUTTON */
.th-btn-primary {
  background-color: #f0f0f3;
  color: #ffffff;
  border: none;
  border-radius: 5px !important;
  height: 40px;
}

.th-btn-primary-br-green {
  background-color: #75cf8f;
  color: #ffffff;
  border: none;
  border-radius: 20px !important;
  height: 40px;
}

.th-btn-primary-br-grey {
  background-color: #dadada;
  color: #565656;
  border: none;
  border-radius: 20px !important;
}

.th-btn-white-home {
  background-color: #ffffff;
  color: #ffffff;
  border: none;
  border-radius: 10px !important;
  height: 40px;
}
.th-btn-upload {
  background-color: #f7f7f7;
  color: #c4c4c4;
  border: none;
  border-radius: 10px !important;
  height: 40px;
}
.th-btn-primary-disabled {
  color: #ffffff;
  background: #d7d7d7;
  /* border-radius: 8px; */
  border: none;
  border-radius: 20px;
}

.th-btn-green {
  background-color: #75cf8f;
  color: #ffffff;
  border: none;
  border-radius: 5px !important;
  filter: drop-shadow(0px 3.21603px 15.2761px rgba(0, 0, 0, 0.25));
}

.th-btn-green-outline {
  color: #75cf8f;
  border: none;
  border-radius: 5px !important;
  border: #75cf8f solid 2px;
  background-color: #ffffff;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 200;
}

.th-btn-white {
  background-color: #ffffff;
  color: #75cf8f;
  border: 2px solid #75cf8f;
  border-radius: 8px;
}

.th-btn-trans {
  background-color: #ffffff00;
  color: #75cf8f;
  border: 1px solid #75cf8f;
}

.th-btn-search {
  background-color: #75cf8f;
  color: #fff;
  border-radius: 8px;
}

.th-btn-grey {
  background-color: #f0f0f3;
  color: #263238;
  border-radius: 8px;
}

.th-btn-black {
  background-color: #000000;
  color: #ffffff !important;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.th-btn-white:focus,
.th-btn-primary:focus,
.th-btn-black:focus {
  outline: none;
}

.th-btn-green-outline:hover {
  background-color: #ffffff;
  outline: none;
  color: #75cf8f;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 200;
}

/* BG Color */
.th-bg-white {
  background: #ffffff;
}

.th-bg-primary {
  background: #75cf8f;
}

.th-bg-green {
  background: #92e3a9;
}

.th-bg-grey {
  background: #e5e5e5;
}

.th-bg-grey-1 {
  background: #f0f0f3;
}

.th-bg-grey-2 {
  background: #ececec;
}

.th-bg-yellow {
  background: #ffc81a;
}

.th-bg-blue {
  background: #52509d;
}

.th-bg-footer {
  background: #2d3748;
}

.th-bg-light-blue {
  background: #eeffff;
}
.th-bg-lt-blue-2 {
  background: #f2fff6;
}

/* Input */
.th-input {
  padding: 10px 20px;
  height: 50px;
  border-radius: 20px !important;
  border-color: #ffffff !important;
}

.th-input :focus {
  box-shadow: none !important;
  outline: none;
}

/* Gradient */
.th-primary-gradient {
  background: linear-gradient(145.22deg, #ffc033 62.28%, #75cf8f 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.th-blue-gradient {
  background: linear-gradient(145.22deg, #49549a 62.28%, #191f44 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.th-search-input:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .th-hidden-sm {
    display: none !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .th-hidden-md {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .th-hidden-lg {
    display: none !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.th-no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.th-no-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

label {
  margin-bottom: 0 !important;
}

/* .slick-prev:before { */
/* background: #FFFFFF; */
/* color: red !important; */
/* font-size: 30px; */
/* } */

/* .slick-next:before { */
/* background: #FFFFFF; */
/* color: red !important; */
/* font-size: 30px; */
/* } */

/* .slick-prev {
  left: -60px;
}

.slick-next {
  right: -40px;
}

.slick-list {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
} */
/* 
.slick-track {
  margin-left: 0 !important;
} */

.th-contact-margin {
  margin-top: -240px;
}

@media screen and (min-width: 768px) {
  .th-contact-margin {
    margin-top: -280px;
  }
}

.th-pb-0 {
  padding-bottom: 0px !important;
}

.th-header-dropbtn {
  background-color: rgba(0, 0, 0, 0);
  /* color: white; */
  /* padding: 16px; */
  /* font-size: 16px; */
  border: none;
  cursor: pointer;
}

.th-header-dropdown {
  position: relative;
  display: inline-block;
}

.th-header-dropdown-content {
  position: absolute;
  top: 32px;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 5px;
  right: 5px;
}

@media screen and (min-width: 768px) {
  .th-header-dropdown-content {
    top: 56px;
  }
}

.th-header-dropdown-content div:hover {
  background-color: #ddd;
}

.th-header-dropdown:hover .th-header-dropdown-content {
  display: block;
}

.th-pointer {
  cursor: pointer;
}

/* .th-header-dropdown:hover .th-header-dropbtn {
  color: #75CF8F;
} */

/* Drawer */
.th-side-drawer-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  top: 0;
  left: 0;
}

.th-side-drawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  z-index: 2000;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.th-pb-0 {
  padding-bottom: 0px !important;
}

.th-border-patch-blue {
  border-left: 8px solid #3f4d90;
}

.th-border-patch-yellow {
  border-left: 8px solid #ffc81a;
}

.th-border-patch-orange {
  border-left: 8px solid #75cf8f;
}

.th-side-drawer.open {
  transform: translateX(0%);
}

/* .slick-next::before {
  content: "\203A" !important;
  color: #000e !important;
  background: #cccccc;
  padding: 0 16px 12px 16px;
  border-radius: 25px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.slick-prev:before {
  content: "\2039" !important;
  color: #000 !important;
  background: #cccccc;
  padding: 0 16px 12px 16px;
  border-radius: 25px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
} */

.th-school-bg-color {
  background-color: #ffffff;
}

@media screen and (max-width: 768px) {
  .th-school-bg-color {
    background-color: #f3f3f3;
  }
}

.th-bg-gradient {
  background: linear-gradient(
    180deg,
    #beffff -71.11%,
    rgba(246, 249, 254, 0) 100%
  );
}

.th-select-1 {
  padding: 2px 4px;
  border: 1.5px solid #75cf8f;
  color: #75cf8f;
  border-radius: 5px;
  font-size: 16px;
}

.th-select-1:focus-visible {
  padding: 2px 4px;
  border: 1.5px solid #75cf8f;
  color: #75cf8f;
  border-radius: 5px;
  font-size: 16px;
}

.th-select-1:focus {
  padding: 2px 4px;
  border: 1.5px solid #75cf8f;
  color: #75cf8f;
  border-radius: 5px;
  font-size: 16px;
}

.th-border {
  width: 80px;
  height: 2px;
  background: #75cf8f;
  margin-top: 5px;
  margin-bottom: 5px;
}

.th-border-white {
  width: 80px;
  height: 2px;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

.th-strike {
  text-decoration: line-through;
}

.th-ht-100 {
  height: 100vh;
}

.th-app-details-btn {
  background: #ffffff;
  padding: 10px;
  width: 90px;
  height: 90px;
  color: rgb(97, 97, 97);
  border-radius: 20px;
  display: grid;
  align-items: center;
}

.th-footer {
  bottom: 0px;
  color: #c4c4c4;
  /* border-top: 1px solid #cacaca; */
  border-radius: 20px 20px 0px 0px;

  background: #ffffff;
  box-shadow: -5px -5px 20px #ffffff, 5px 5px 20px rgba(174, 174, 192, 0.5);
  border-radius: 10px 10px 0px 0px;
}

.th-footer-active {
  color: #75cf8f;
}

/* Card shadow */
.th-box-shadow {
  box-shadow: 0px 15px 55px rgba(0, 0, 0, 0.05);
}

/* Horizontal rule  */
.th-hr {
  padding-top: 0px;
  margin-top: 0px;
}

.th-txt-truncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.th-txt-truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.th-txt-truncate-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.th-txt-truncate-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

input:focus-visible {
  outline-offset: none !important;
  outline: none !important;
}

textarea:focus-visible {
  outline-offset: none !important;
  outline: none !important;
}

select:focus-visible {
  outline-offset: none !important;
  outline: none !important;
}

.th-line-before-text:before {
  content: "";
  display: block;
  width: 60px;
  height: 2px;
  background: #92e3a9;
  left: 16;
  top: 34%;
  position: absolute;
}

.th-border-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.th-border-bottom {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media screen and (min-width: 1024px) {
  .th-village-height-scroll {
    height: calc(80vh);
    overflow-y: scroll;
    padding-bottom: 5%;
  }
}

@media screen and (min-width: 1024px) {
  .th-gallery-height-scroll {
    height: calc(85vh);
    overflow-y: scroll;
  }
}

@media screen and (min-width: 1024px) {
  .th-90-height-scroll {
    height: 88vh;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 768px) {
  .th-90-mobile-scroll {
    height: 88vh;
    overflow-y: scroll;
  }
}

.th-container {
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .th-container {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.th-side-header {
  width: 85px;
}

.th-form {
  width: 100%;
  padding: 16px 16px;
  font-size: 14px;
  border: none;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481),
    0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
}

.th-btn-search {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #75cf8f;
  color: #fff;
  /* padding-top: 4%; */
  /* padding: 16px 8px; */
  border-radius: 8px;
  font-size: 14px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481),
    0px 20px 13px rgba(0, 0, 0, 0.01),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
}

.th-search {
  border: none;
  outline: none;
}

.th-scrolling-wrapper {
  overflow-x: auto;
}

/* .th-card-block{
	height: 120px;
  width: 100%;
	border: none;
	background-size: cover;
	transition: all 0.2s ease-in-out !important;
	border-radius: 15px;
} */
